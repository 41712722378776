<template>
  <a class="widget quick-category">
    <div class="quick-category-head">
        <span class="quick-category-icon qc-primary rounded-circle">
            <i class="las la-shopping-cart"></i>
        </span>
        <div class="ml-auto">
            <div class="quick-comparison " :class="soldIncrease? 'qcompare-success': 'qcompare-danger'">
                <span>{{ percentage }}%</span>
                <i class="las " :class="soldIncrease? 'la-arrow-up': 'la-arrow-down'"/>
            </div>
        </div>
    </div>
    <div class="quick-category-content">
        <h3>{{ currentMonthSoldProducts }}</h3>
        <p class="font-17 text-primary mb-0">Produits vendus</p>
    </div>
</a>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            orders: 'market/orders'
        }),
        lastMonthSoldProducts(){
            const now = new Date(); 
            const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1); 
            const orders = [...this.orders].filter(order => { 
                const createdAt = new Date(order.createdAt); 
                return createdAt.getMonth() === lastMonth.getMonth() && createdAt.getFullYear() === lastMonth.getFullYear() && order.status === 'Paid'; 
            }); 
            // Calculer le nombre total de produits vendus 
            let totalQuantity = 0; 
            orders.forEach(order => { 
                const details = JSON.parse(order.details); 
                details.forEach(item => { 
                    totalQuantity += item.quantity; 
                }); 
            }); 
            return totalQuantity
        },
        currentMonthSoldProducts () { 
            const now = new Date(); 
            const currentMonth = now.getMonth(); 
            const currentYear = now.getFullYear(); 
            const orders = [...this.orders].filter(order => { 
                const createdAt = new Date(order.createdAt); 
                return createdAt.getMonth() === currentMonth && createdAt.getFullYear() === currentYear && order.status === 'Paid'; 
            }); 
            // Calculer le nombre total de produits vendus 
            let totalQuantity = 0; 
            orders.forEach(order => { 
                const details = JSON.parse(order.details); 
                details.forEach(item => { 
                    totalQuantity += item.quantity; 
                }); 
            }); 
            return totalQuantity
        },
        soldIncrease(){
            return this.currentMonthSoldProducts > this.lastMonthSoldProducts
        },
        percentage(){
            if(this.lastMonthSoldProducts  === 0) return 100
            if(this.soldIncrease) return Math.round(((this.currentMonthSoldProducts - this.lastMonthSoldProducts)/this.lastMonthSoldProducts) * 100)
            return Math.round(((this.lastMonthSoldProducts - this.currentMonthSoldProducts)/this.lastMonthSoldProducts) * 100)
        }

    }

}
</script>