<template>
  <div class="widget widget-table-one">
    <div class="widget-heading">
        <h5 class="">Top 5 produits les plus vendus</h5>
    </div>
    <div class="widget-content">
        <TopProduct v-for="p in topSellingProducts" :key="p.product" :productItem="p" />
        <!-- <a class="btn btn-block btn-primary">View All</a> -->
    </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import TopProduct from './TopProduct.vue';
export default {
    components: {TopProduct},
    data(){
        return {
 
        }
    },
    computed: {
        ...mapGetters({
            orders: 'market/orders'
        }),
        paidOrders(){
            return [...this.orders].filter(item => item.status === 'Paid')
        },
        topSellingProducts() { 
            const productMap = {}; 
            this.paidOrders.forEach(order => { 
                const details = JSON.parse(order.details); 
                details.forEach(item => { 
                    if (productMap[item.product]) { 
                        productMap[item.product] += item.quantity; 
                    } else { 
                        productMap[item.product] = item.quantity; 
                    } 
                }); 
            }); // Convertir le productMap en tableau et trier par quantité décroissante 
            const sortedProducts = Object.keys(productMap) .map(product => ({ 
                product, quantity: productMap[product] 
            })) .sort((a, b) => b.quantity - a.quantity); 
           
            return sortedProducts.slice(0,5); 
        }
    }
}
</script>