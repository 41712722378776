<template>
  <a class="widget quick-category">
    <div class="quick-category-head">
        <span class="quick-category-icon qc-secondary rounded-circle">
            <i class="las la-hand-holding-usd"></i>
        </span>
        <div class="ml-auto">
            <div class="quick-comparison" :class="billIncrease? 'qcompare-success': 'qcompare-danger'">
                <span>{{percentage}}%</span>
                <i class="las" :class="billIncrease? 'la-arrow-up': 'la-arrow-down'"></i>
            </div>
        </div>
    </div>
    <div class="quick-category-content">
        <h3>{{ currentMonthBills }} Fcfa</h3>
        <p class="font-17 text-secondary mb-0">Ventes du mois</p>
    </div>
</a>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            factures: 'market/factures'
        }),
        lastMonthBills() {
            const now = new Date();
            const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1);
            const totalAmount = [...this.factures].filter(bill => {
                const createdAt = new Date(bill.createdAt);
                return createdAt.getMonth() === lastMonth.getMonth() &&
                        createdAt.getFullYear() === lastMonth.getFullYear() &&
                        bill.status === 'Paid';
                })
                .reduce((total, bill) => total + bill.amount, 0);
            return totalAmount;
        },
        currentMonthBills() {
            const now = new Date();
            const currentMonth = now.getMonth();
            const currentYear = now.getFullYear();

            const totalAmount = [...this.factures].filter(bill => {
                const createdAt = new Date(bill.createdAt);
                return createdAt.getMonth() === currentMonth &&
                        createdAt.getFullYear() === currentYear &&
                        bill.status === 'Paid';
                })
                .reduce((total, bill) => total + bill.amount, 0);

            return totalAmount;
        },
        billIncrease(){
            return this.currentMonthBills > this.lastMonthBills
        },
        percentage(){
            if(this.lastMonthBills  === 0) return 100
            if(this.billIncrease) return Math.round(((this.currentMonthBills - this.lastMonthBills)/this.lastMonthBills) * 100)
            return Math.round(((this.lastMonthBills - this.currentMonthBills)/this.lastMonthBills) * 100)
        }


    }
}
</script>