<template>
  <div class="widget widget-chart-one">
    <div class="widget-heading">
        <h5 class="">Status des Factures</h5>
    </div>
    <div class="widget-content">
        <div id="orderStatus" class="">
            <apexchart type="bar" :options="options" :series="generateSeriesForYear"></apexchart>
            <!-- {{ generateSeriesForYear }} -->
        </div>
    </div>
</div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            factures: 'market/factures'
        }),
        generateSeriesForYear() { 
            const now = new Date();
            const year = now.getFullYear()
            const statuses = [{libelle: 'Payée', code: 'Paid'}, {libelle: 'Brouillons', code: 'Pending'}, {libelle:'En attente de paiement', code: 'Sent' }]; 
            const series = statuses.map(status => (
                {
                    name: status.libelle, 
                    code: status.code,
                    data: Array(12).fill(0) // Initialiser les données pour chaque mois de l'année en cours 
                }
            )); 
            [...this.factures].forEach(facture => { 
                const createdAt = new Date(facture.createdAt); 
                if (createdAt.getFullYear() === year) { 
                    const month = createdAt.getMonth(); 
                    const seriesItem = series.find(item => item.code === facture.status); 
                    if (seriesItem) { 
                        seriesItem.data[month] += 1; // Incrémenter le compteur pour le mois en cours 
                    } 
                    } 
                }
            ); 
            return series; 
        },
        options (){
            return {
                // series: this.generateSeriesForYear,
                chart: {
                    type: 'bar',
                    height: 350,
                    fontFamily: 'Poppins, sans-serif',
                    toolbar: {
                        show: false
                    },
                },
                colors: ['#009688', '#e2a03f','#2196f3','#e7515a'],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: ['Janvier', 'Féb', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                    labels: {
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            fontSize: '12px',
                            fontFamily: 'Poppins, sans-serif',
                            cssClass: 'apexcharts-yaxis-title',
                        },
                    }
                },
                yaxis: {
                    labels: {
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            fontSize: '12px',
                            fontFamily: 'Poppins, sans-serif',
                            cssClass: 'apexcharts-yaxis-title',
                        },
                    }
                },
                grid: {
                    borderColor: '#e0e6ed',
                    strokeDashArray: 8,
                    xaxis: {
                        lines: {
                            show: true
                        }
                    },   
                    yaxis: {
                        lines: {
                            show: true,
                        }
                    },
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: -10
                    }, 
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'center',
                    offsetY: 0,
                    fontSize: '13px',
                    fontFamily: 'Poppins, sans-serif',
                    markers: {
                        width: 10,
                        height: 10,
                        strokeWidth: 0,
                        strokeColor: '#fff',
                        fillColors: undefined,
                        radius: 12,
                        onClick: undefined,
                        offsetX: 0,
                        offsetY: 0
                    },    
                    itemMargin: {
                        horizontal: 0,
                        vertical: 20
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    theme: 'dark',
                    marker: {
                        show: true,
                    },
                    x: {
                        show: false,
                    }
                },
            }
        }
    }
}
</script>