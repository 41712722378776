<template>
  <div class="widget widget-chart-one">
    <div class="widget-heading">
        <h5 class="">Transactions de l'année {{ currentYear }}</h5>
        <ul class="tabs tab-pills">
            <li>
                <div class="dropdown  custom-dropdown-icon">
                    <a class="dropdown-toggle" href="#" role="button" id="customDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span>Options</span> <i class="las la-angle-down"></i></a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="customDropdown">
                        <!-- <a class="dropdown-item" data-value="Settings" href="javascript:void(0);">Quarterly</a>
                        <a class="dropdown-item" data-value="Settings" href="javascript:void(0);">Half Yearly</a> -->
                        <!-- <a class="dropdown-item" data-value="Mail" href="javascript:void(0);">Mail</a> -->
                        <a class="dropdown-item" data-value="Print" href="javascript:void(0);">Print</a>
                        <a class="dropdown-item" data-value="Download" href="javascript:void(0);">Download</a>
                        <a class="dropdown-item" data-value="Share" href="javascript:void(0);">Share</a>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div class="widget-content">
        <div class="tabs tab-content">
            <div id="content_1" class="tabcontent"> 
                <div id="transactionsMonthly">
                    <apexchart height="500" :options="options" :series="yearlyTransactions"/>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            factures: 'market/factures'
        }),
        currentYear(){
            const now = new Date(); 
            return now.getFullYear(); 
        },
        yearlyTransactions() { 
            const totalSales = Array(12).fill(0); // Initialiser un tableau pour les ventes totales pour chaque mois 
            const totalCommissions = Array(12).fill(0); // Initialiser un tableau pour les commissions totales pour chaque mois 
            [...this.factures].forEach(facture => { 
                const createdAt = new Date(facture.createdAt); 
                if (createdAt.getFullYear() === this.currentYear && facture.status === 'Paid') { 
                    const month = createdAt.getMonth(); 
                    totalSales[month] += facture.amount; 
                    totalCommissions[month] = totalSales[month] * 0.1; // Calculer 10% des ventes totales pour les commissions 
                } 
            }); 
            return [ 
                { name: 'Total Ventes', data: totalSales }, 
                // { name: 'Total Commissions', data: totalCommissions } 
            ]; 
        },

        totalPaidInvoicesForCurrentYear() { 
            const totalAmount = [...this.factures] .filter(facture => { 
                const createdAt = new Date(facture.createdAt); 
                return createdAt.getFullYear() === this.currentYear && facture.status === 'Paid'; 
            }) .reduce((total, facture) => total + facture.amount, 0); 
            return totalAmount; 
        },
        
        options(){
            return {
                chart: {
                    fontFamily: 'Poppins, sans-serif',
                    // height: 320,
                    type: 'area',
                    zoom: {
                        enabled: false
                    },
                    dropShadow: {
                        enabled: true,
                        opacity: 0.2,
                        blur: 5,
                        left: -7,
                        top: 22
                    },
                    toolbar: {
                        show: false
                    },
                    events: {
                        // mounted: function(ctx, config) {
                        //     const highest1 = ctx.getHighestValueInSeries(0);
                        //     const highest2 = ctx.getHighestValueInSeries(1);
                        //     ctx.addPointAnnotation({
                        //         x: new Date(ctx.w.globals.seriesX[0][ctx.w.globals.series[0].indexOf(highest1)]).getTime(),
                        //         y: highest1,
                        //         label: {
                        //         style: {
                        //             cssClass: 'd-none'
                        //         }
                        //         },
                        //         customSVG: {
                        //         SVG: '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="#009688" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg>',
                        //         cssClass: undefined,
                        //         offsetX: -8,
                        //         offsetY: 5
                        //         }
                        //     })
                        //     ctx.addPointAnnotation({
                        //         x: new Date(ctx.w.globals.seriesX[1][ctx.w.globals.series[1].indexOf(highest2)]).getTime(),
                        //         y: highest2,
                        //         label: {
                        //         style: {
                        //             cssClass: 'd-none'
                        //         }
                        //         },
                        //         customSVG: {
                        //         SVG: '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="#e7515a" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg>',
                        //         cssClass: undefined,
                        //         offsetX: -8,
                        //         offsetY: 5
                        //         }
                        //     })
                        // },
                    }
                },
                colors: ['#009688', '#e7515a'],
                dataLabels: {
                    enabled: false
                },
                markers: {
                    discrete: [{
                        seriesIndex: 0,
                        dataPointIndex: 7,
                        fillColor: '#000',
                        strokeColor: '#000',
                        size: 5
                    }, {
                        seriesIndex: 2,
                        dataPointIndex: 11,
                        fillColor: '#000',
                        strokeColor: '#000',
                        size: 4
                    }]
                },
                subtitle: {
                    text: `Revenu de ${this.currentYear}`,
                    align: 'left',
                    margin: 0,
                    offsetX: -10,
                    offsetY: 35,
                    floating: false,
                    style: {
                        fontSize: '14px',
                        color:  '#888ea8'
                    }
                },
                title: {
                    text: `${this.totalPaidInvoicesForCurrentYear} Fcfa`,
                    align: 'left',
                    margin: 0,
                    offsetX: -10,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: '25px',
                        color:  '#515365'
                    },
                },
                stroke: {
                    show: true,
                    curve: 'smooth',
                    width: 2,
                    lineCap: 'square'
                },
                
                labels: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septempbre', 'Octobre', 'Novembre', 'Décembre'],
                xaxis: {
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    crosshairs: {
                        show: true
                    },
                    labels: {
                        offsetX: 0,
                        offsetY: 5,
                        style: {
                        fontSize: '12px',
                        fontFamily: 'Poppins, sans-serif',
                        cssClass: 'apexcharts-xaxis-title',
                        },
                    }
                },
                yaxis: {
                    labels: {
                        formatter: function(value) {
                            return value + ' Fcfa'
                        },
                        offsetX: -15,
                        offsetY: 0,
                        style: {
                        fontSize: '12px',
                        fontFamily: 'Poppins, sans-serif',
                        cssClass: 'apexcharts-yaxis-title',
                        },
                    }
                },
                grid: {
                    borderColor: '#e0e6ed',
                    strokeDashArray: 8,
                    xaxis: {
                        lines: {
                        show: true
                        }
                    },   
                    yaxis: {
                        lines: {
                        show: true,
                        }
                    },
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: -10
                    }, 
                }, 
                legend: {
                    position: 'top',
                    horizontalAlign: 'right',
                    offsetY: -50,
                    fontSize: '13px',
                    fontFamily: 'Poppins, sans-serif',
                    markers: {
                        width: 10,
                        height: 10,
                        strokeWidth: 0,
                        strokeColor: '#fff',
                        fillColors: undefined,
                        radius: 12,
                        onClick: undefined,
                        offsetX: 0,
                        offsetY: 0
                    },    
                    itemMargin: {
                        horizontal: 0,
                        vertical: 20
                    }
                },
                tooltip: {
                    theme: 'dark',
                    marker: {
                        show: true,
                    },
                    x: {
                        show: false,
                    }
                },
                fill: {
                    type:"gradient",
                    gradient: {
                        type: "vertical",
                        shadeIntensity: 1,
                        inverseColors: !1,
                        opacityFrom: .28,
                        opacityTo: .05,
                        stops: [45, 100]
                    }
                },
                responsive: [{
                    breakpoint: 575,
                    options: {
                        legend: {
                            offsetY: -30,
                        },
                    },
                }]
            }
        }

    }
}
</script>