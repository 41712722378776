<template>
  <div class="row layout-top-spacing" style="margin-top: 50px;">
    <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <product-sold/>
    </div>
    <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <new-orders/>
    </div>
    <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <Monthly-sales/>
    </div>
    <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        Abonnements
    </div>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <monthly-transactions/>
    </div>
    <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <bill-status/>
    </div>
    <div class="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <top-sell-product/>
    </div>

  </div>
</template>

<script>
import ProductSold from '../../components/market/dashboard/ProductSold.vue';
import NewOrders from '../../components/market/dashboard/NewOrders.vue';
import MonthlySales from '../../components/market/dashboard/MonthlySales.vue';
import MonthlyTransactions from '../../components/market/dashboard/MonthlyTransactions.vue';
import BillStatus from '../../components/market/dashboard/BillStatus.vue';
import TopSellProduct from '../../components/market/dashboard/TopSellProduct.vue';
export default {
    components:{
        ProductSold,
        NewOrders,
        MonthlySales,
        MonthlyTransactions,
        BillStatus,
        TopSellProduct
    }

}
</script>