<template>
  <a class="widget quick-category">
    <div class="quick-category-head">
        <span class="quick-category-icon qc-warning rounded-circle">
            <i class="las la-box"></i>
        </span>
        <div class="ml-auto">
            <div class="quick-comparison" :class="orderIncrease? 'qcompare-success': 'qcompare-danger'">
                <span>{{ percentage }}%</span>
                <i class="las" :class="orderIncrease? 'la-arrow-up': 'la-arrow-down'"/>
            </div>
        </div>
    </div>
    <div class="quick-category-content">
        <h3>{{ currentMonthOrders }}</h3>
        <p class="font-17 text-warning mb-0">Commandes du mois</p>
    </div>
</a>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            orders: 'market/orders'
        }),
        lastMonthOrders(){
            const now = new Date(); 
            const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1); 
            return  [...this.orders].filter(order => { 
                const createdAt = new Date(order.createdAt); 
                return createdAt.getMonth() === lastMonth.getMonth() && createdAt.getFullYear() === lastMonth.getFullYear(); 
            }).length; 
        },
        currentMonthOrders(){
            const now = new Date(); 
            const currentMonth = now.getMonth(); 
            const currentYear = now.getFullYear(); 
            return  [...this.orders].filter(order => { 
                const createdAt = new Date(order.createdAt); 
                return createdAt.getMonth() === currentMonth && createdAt.getFullYear() === currentYear; 
            }).length;
        },
        orderIncrease(){
            return this.currentMonthOrders > this.lastMonthOrders
        },
        percentage(){
            if(this.lastMonthOrders  === 0) return 100
            if(this.orderIncrease) return Math.round(((this.currentMonthOrders - this.lastMonthOrders)/this.lastMonthOrders) * 100)
            return Math.round(((this.lastMonthOrders - this.currentMonthOrders)/this.lastMonthOrders) * 100)
        }
    }
}
</script>